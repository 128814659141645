import IconBoxAdd from '@/assets/icons/icon-box-add.svg?component';
import IconCutlery from '@/assets/icons/icon-cutlery.svg?component';
import IconHome from '@/assets/icons/icon-home.svg?component';
import IconPersonOutline from '@/assets/icons/icon-person-outline.svg?component';
import IconSignOut from '@/assets/icons/icon-sign-out.svg?component';
import type { VNode } from 'vue';
import { NuxtLink } from '#components';
import './Navbar.css';
import { getGreenLogo } from '@/utils/logo';

const ActivateCalendarModal = defineAsyncComponent(
  () => import('@/components/ActivateCalendarModal')
);

export default defineComponent({
  name: 'Navbar',
  setup() {
    const { localeProperties } = useI18n();
    const greenLogo = computed(() =>
      getGreenLogo(localeProperties.value.brand)
    );
    const whiteLogo = computed(() =>
      getWhiteLogo(localeProperties.value.brand)
    );
    const { currentCustomer } = useCurrentCustomer();
    const { isRenewalBlocked } = useCustomerSubscriptions();
    const { getCustomerOffers } = useOffersApi();
    const { data: offer } = getCustomerOffers();

    const isDisabled = computed<boolean | undefined>(
      () => isRenewalBlocked.value || undefined
    );

    const isLoyaltyProgramVisible = computed(
      () => localeProperties.value.loyaltyProgramName
    );

    const route = useRoute();

    return {
      isDisabled,
      currentCustomer,
      isLoyaltyProgramVisible,
      greenLogo,
      whiteLogo,
      route,
      offer,
    };
  },
  render(): VNode {
    return (
      <>
        <ActivateCalendarModal />
        <this.greenLogo class="print-logo" />
        <nav class="navbar" data-disabled={this.isDisabled}>
          <div class="nav-container">
            <NuxtLink
              aria-label={this.$t('navbar.logo_alt')}
              class="logo-link"
              to={{ name: 'index', query: this.route.query }}
            >
              <this.whiteLogo class="logo" />
            </NuxtLink>
            <ul class="nav-links">
              <li>
                <NuxtLink
                  class="nav-link"
                  to={{ name: 'index', query: this.route.query }}
                >
                  <IconHome aria-hidden class="icon" />
                  {this.$t('navbar.home')}
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  class="nav-link"
                  to={{ name: 'recipes', query: this.route.query }}
                >
                  <IconCutlery aria-hidden class="icon" />
                  {this.$t('navbar.recipes')}
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  class="nav-link"
                  to={{
                    name: 'additional-products',
                    query: this.route.query,
                  }}
                >
                  <IconBoxAdd aria-hidden class="icon" />
                  {this.$t('navbar.additional_products')}
                </NuxtLink>
              </li>

              <li class={{ 'hidden md:flex': this.isDisabled }}>
                <NuxtLink
                  class={[
                    'nav-link',
                    {
                      'router-link-active router-parent-active':
                        String(this.route.meta.parent)?.includes('more') ||
                        this.route.meta.parent === '#back',
                    },
                  ]}
                  data-testid="more-link"
                  to={{ name: 'more' }}
                >
                  <IconPersonOutline aria-hidden class="icon" />
                  <span
                    class={{
                      'notification-dot':
                        (this.isLoyaltyProgramVisible &&
                          !this.currentCustomer?.inLoyaltyProgram) ||
                        this.offer?.content ||
                        new Date() < new Date('2025-04-14'),
                    }}
                  >
                    {this.$t('navbar.account')}
                  </span>
                </NuxtLink>
              </li>
              <li class={{ 'hidden md:flex': !this.isDisabled }}>
                <NuxtLink
                  class="nav-link sign-out-link"
                  to={{ name: 'sign-out' }}
                >
                  <IconSignOut aria-hidden class="icon" />
                  {this.$t('global.log_out')}
                </NuxtLink>
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  },
});
